<template>
  <div class="levelorder">
    <div class="top_bar">
      <div class="top_bar_icon" @click="handleRouter">
        <van-icon name="arrow-left" color="#7e7e7e" size="16" />
      </div>
      <div>发货订单</div>
    </div>
    <van-tabs
      v-model="active"
      background="#f5f5f5"
      title-active-color="#0E3592"
      color="#0E3592"
      line-width="26"
      line-height="3"
      title-inactive-color="#666"
      @click="onClick"
    >
      <van-tab v-for="(item,index) in classifyList" :title="item" :key="index">
        <div class="content_item" v-for="childItem in List" :key="childItem.order_id">
          <div class="item_title">
            <div>商品名称</div>
            <div>{{childItem.goods_name}}</div>
          </div>
          <div class="item_center" :class="[childItem.shipping_status!=1?'center_height':'']">
            <div class="item">
              <div class="item_left">积分&规格：</div>
              <div class="item_right">
                <span class="item_right_price">{{childItem.shop_price}}</span>
                <span class="item_right_num">/{{childItem.spu}}</span>
              </div>
            </div>
            <div class="item">
              <div class="item_left">下单号：</div>
              <div>{{childItem.order_sn}}</div>
            </div>
            <div class="item">
              <div class="item_left">下单时间：</div>
              <div>{{childItem.add_time}}</div>
            </div>
            <div class="item">
              <div class="item_left">订单状态：</div>
              <div
                :class="[childItem.shipping_status==1? 'wait_status':childItem.shipping_status==2?'delivery_status':'complete_status']"
              >{{childItem.shipping_status==1?"待发货":childItem.shipping_status==2?"已发货":"已完成"}}</div>
            </div>
            <div class="item">
              <div class="item_left">收货人姓名：</div>
              <div>{{childItem.consignee_name}}</div>
            </div>
            <div class="item">
              <div class="item_left">收货人联系方式：</div>
              <div>{{childItem.consignee_mobile}}</div>
            </div>
            <div class="item">
              <div class="item_left">收货地址：</div>
              <div>{{childItem.address}}</div>
            </div>
            <div class="item">
              <div class="item_left">备注：</div>
              <div>{{childItem.note}}</div>
            </div>
            <div class="item" v-show="childItem.shipping_status!=1">
              <div class="item_left">物流号：</div>
              <div class="right_complete">
                <span class="complete_number">{{childItem.shipping_code}}</span>
                <span :data-clipboard-text="childItem.shipping_code" class="copy" @click="copy">复制</span>
              </div>
            </div>
          </div>
          <div class="item_footer" v-show="childItem.shipping_status==1">
            <div class="footer_right" @click="handleBuy(childItem.order_id)">立即发货</div>
          </div>
        </div>
      </van-tab>
      <div class="none" v-show="(List instanceof Array)&&List.length==0">暂无商品</div>
    </van-tabs>
    <!-- 弹框 -->
    <van-overlay :show="show" @click="show = false" :lock-scroll="false">
      <div class="exchange_popup">
        <div class="popup_img" @click="show = false">
          <img src="../../assets/image/close.svg" alt />
        </div>
        <div class="popup_content" @click.stop>
          <div class="popup_title">请输入物流单号</div>
          <div class="number_input">
            <input v-model="numbers" placeholder="输入内容" @blur="blurIn" />
          </div>
          <div class="btn btn_w" @click="handleConfirm">确认</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import Clipboard from "clipboard";
export default {
  data() {
    return {
      active: 0,
      List: [],
      show: false,
      numbers: "",
      classifyList: [],
      orderId: ""
    };
  },
  computed: {
    scrollHeight() {
      return document.documentElement.scrollTop || document.body.scrollTop || 0;
    }
  },
  methods: {
    handleRouter() {
      this.$router.go(-1);
    },
    // 立即发货
    handleBuy(id) {
      this.show = true;
      this.orderId = id;
    },
    async handleConfirm() {
      const data = await this.$api.delivery({
        order_id: this.orderId,
        shipping_code: this.numbers
      });
      if (data.data.code === 1) {
        this.$notify({ type: "success", message: data.data.msg });
        this.handleGetSuborder(0);
        this.numbers = "";
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
        this.numbers = "";
      }
      this.show = false;
    },
    onClick(name, title) {
      console.log(name, title);
      this.handleGetSuborder(name);
    },
    async handleGetOrderStatus() {
      const data = await this.$api.getOrderStatus();
      console.log(data, "data");
      if (data.data.code === 1) {
        this.classifyList = data.data.data;
        this.handleGetSuborder(0);
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
      }
    },
    async handleGetSuborder(status) {
      const data = await this.$api.getSuborder({
        shipping_status: status
      });
      console.log(data, "data");
      if (data.data.code === 1) {
        this.List = data.data.data;
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
        this.List = [];
      }
    },
    copy() {
      var clipboard = new Clipboard(".copy");
      clipboard.on("success", e => {
        console.log("复制成功", e);
        this.$notify({ type: "success", message: "复制成功!" });
        // 释放内存
        clipboard.destroy();
      });
      // clipboard.on('error', e => {
      //   // 不支持复制
      //   this.$notify({ type: "success", message: "手机权限不支持复制功能" });

      //   // Toast({
      //   //   message: '手机权限不支持复制功能'
      //   // })
      //   console.log('该浏览器不支持自动复制')
      //   // 释放内存
      //   clipboard.destroy()
      // })
    },
    blurIn() {
      window.scrollTo(0, Math.max(this.scrollHeight - 1, 0));
    }
  },
  mounted() {
    this.handleGetOrderStatus();
  }
};
</script>
<style lang="scss" scoped>
.levelorder {
  width: 375px;
  height: auto;
  min-height: 100vh;
  background: #f5f5f5;
  padding-top: 40px;
  box-sizing: border-box;
  .content_item {
    width: 375px;
    min-height: 241px;
    // height:auto;
    // max-height: 291px;
    background: rgba(255, 255, 255, 1);
    margin-bottom: 10px;
    .item_title {
      width: 100%;
      height: 45px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      color: rgba(51, 51, 51, 1);
      line-height: 16px;
      padding: 0 12px;
      box-sizing: border-box;
      border-bottom: 1px solid #e5e5e5;
    }
    .item_center {
      width: 351px;
      margin: 0 auto;
      // height: 158px;
      font-size: 14px;
      line-height: 15px;
      padding-top: 15px;
      color: #333;
      .item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 21px;
        .item_left {
          color: rgba(102, 102, 102, 1);
        }
        .item_right {
          line-height: 14px;

          .item_right_price {
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(254, 29, 29, 1);
            margin-right: 16px;
          }
          .item_right_num {
            color: #666;
          }
        }
        .wait_status {
          color: rgba(255, 99, 0, 1);
        }
        .delivery_status {
          color: rgba(0, 177, 0, 1);
        }
        .complete_status {
          color: rgba(153, 153, 153, 1);
        }
        .right_complete {
          color: rgba(50, 125, 251, 1);
          .complete_number {
            margin-right: 20px;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .center_height {
      height: auto;
      padding-bottom: 10px;
    }
    .item_footer {
      border-top: 1px solid #e5e5e5;
      display: flex;
      justify-content: flex-end;
      height: 50px;
      align-items: center;
      padding-right: 12px;
      box-sizing: border-box;
      .footer_left {
        width: 79px;
        height: 30px;
        background: rgba(216, 216, 216, 1);
        border-radius: 15px;
        font-size: 14px;
        color: rgba(102, 102, 102, 1);
        line-height: 30px;
        text-align: center;
      }
      .footer_right {
        width: 90px;
        height: 30px;
        background: rgba(0, 177, 0, 1);
        border-radius: 15px;
        font-size: 14px;
        color: rgba(255, 255, 255, 1);
        line-height: 30px;
        text-align: center;
        margin-left: 17px;
      }
      .footer_appeal {
        width: 79px;
        height: 30px;
        background: rgba(0, 177, 0, 1);
        border-radius: 15px;
        line-height: 30px;
        font-size: 14px;
        color: rgba(255, 255, 255, 1);
        text-align: center;
      }
      .footer_appeal_Have {
        width: 79px;
        height: 30px;
        background: rgba(255, 93, 93, 1);
        border-radius: 15px;
        line-height: 30px;
        font-size: 14px;
        color: rgba(255, 255, 255, 1);
        text-align: center;
      }
    }
  }
  .exchange_popup {
    position: relative;
    .popup_img {
      width: 22px;
      height: 22px;
      position: absolute;
      top: 104px;
      right: 32px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .popup_content {
      width: 310px;
      height: 210px;
      background: rgba(255, 255, 255, 1);
      border-radius: 6px;
      position: absolute;
      top: 136px;
      right: 32px;
      padding-top: 20px;
      box-sizing: border-box;
      .popup_title {
        text-align: center;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
        line-height: 20px;
      }
      .number_input {
        width: 210px;
        height: 40px;
        margin-top: 30px;
        margin-left: 50px;
        input {
          -webkit-appearance: none; /*去除系统默认的样式*/
          width: 210px;
          height: 40px;
          border-radius: 25px;
          border: none;
          border: 1px solid rgba(193, 193, 193, 1);
          padding: 0 10px;
          font-size: 16px;
          box-sizing: border-box;
          // color: rgba(153, 153, 153, 1);
          line-height: 22px;
          background: rgba(247, 247, 247, 1);
          text-align: center;
        }
        input::-webkit-input-placeholder {
          color: #999;
          font-size: 13px;
        }
      }

      .btn_w {
        position: absolute;
        width: 210px;
        bottom: 30px;
        left: 50px;
      }
    }
    .content_h {
      height: 210px;
    }
  }
  .none {
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    color: #999;
    margin-top: 100px;
  }
}
</style>
